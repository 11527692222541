<template>
  <div class="content-block" v-if="vendorHint">
    <h2 class="lg-title">
      <div class="title-word">廠商商品團主提示(開團後此訊息不會顯示)</div>
    </h2>
    <div class="container">
      <div class="product-text" v-html="vendorHint"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('create-group', {
      vendorHint: state => state.vendorHint
    })
  }
};
</script>
