<template>
  <div class="content-block">
    <h2 class="lg-title">
      <div class="title-word">商品介紹</div>
    </h2>
    <div class="input-block">
      <div class="input-wrap">
        <VueEditor
          v-model="content"
          :editorOptions="editorSettings"
          :editorToolbar="customToolbar"
          @image-added="handleImageAdded"
          :use-custom-image-handler="true"
        ></VueEditor>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutation, mapMutations } from 'vuex';
import { VueEditor } from 'vue2-editor';
import EditorMixin from '@/mixins/vue2-editor';

export default {
  mixins: [EditorMixin],
  components: {
    VueEditor
  },
  props: {
    placeholder: {
      required: false,
      type: String,
      default() {
        return '';
        // return '如有下單請務必加入開團主的通訊軟體，以免收不到通知訊息';
      }
    }
  },
  computed: {
    ...mapState('create-group', {
      _content: state => state.content
    }),
    content: {
      get() {
        return this._content;
      },
      set(val) {
        this.SET_CONTENT(val);
      }
    }
  },
  methods: {
    ...mapMutations('create-group', ['SET_CONTENT'])
  }
};
</script>
