<template>
  <div
    class="modal fade"
    :id="`modal-select-provider${index}`"
    tabindex="-1"
    role="dialog"
    aria-labelledby="delalert"
  >
    <div class="modal-dialog modal-sm modal-select">
      <div class="modal-close" data-dismiss="modal"></div>
      <div class="modal-content">
        <div class="modal-title">請選擇廠商</div>
        <SearchInput
          placeholder="請輸入品項名稱"
          class="search-filter"
          @on-search="updateSearch"
        />
        <div class="search-list-menu">
          <div
            class="item"
            v-for="(company, index) in dataList"
            :key="index"
            :data-company-id="company.id"
            :data-company-name="company.name"
            @click="chooseCompany"
          >
            {{ company.name }}
          </div>
        </div>
        <!-- //TODO: 載入 -->
        <span v-if="!loadAll" @click="getData" class="text-center">
          載入更多
        </span>
        <!-- <scroll-loader
          v-if="!loadAll"
          :loader-method="getData"
          :loader-enable="!loadAll"
        ></scroll-loader> -->
      </div>
    </div>
  </div>
</template>

<script>
import { getVendors } from '@/api/vendors';
import PaginateMixin from '@/mixins/paginate';
import PaginateExtendMixin from '@/mixins/paginate-extend-ref';
import SearchInput from '@/components/SearchInput';

export default {
  mixins: [PaginateMixin, PaginateExtendMixin],
  components: {
    SearchInput
  },
  props: {
    index: {
      required: true,
      type: Number
    }
  },
  data() {
    return {
      api: getVendors,
      dir: 'asc',
      sortBy: 'id'
    };
  },
  methods: {
    chooseCompany(e) {
      let company = {
        index: this.index,
        id: e.target.dataset.companyId,
        name: e.target.dataset.companyName
      };
      this.$emit('choose-company', company);
      $(`#modal-select-provider${this.index}`).modal('hide');
    },
    updateSearch(search) {
      this.search = search;
    }
  },
  mounted() {}
};
</script>
