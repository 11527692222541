<template>
  <div
    class="modal fade"
    id="modal-helper-fee"
    tabindex="-1"
    role="dialog"
    aria-labelledby="delalert"
  >
    <div class="modal-dialog modal-sm modal-helper">
      <div class="modal-content">
        <div class="modal-title">服務費</div>
        <div class="helper-content">
          除了商品外，如有額外收據的費用：如替換家的服務費用或郵寄費用。
        </div>
        <div class="modal-btn-block">
          <div class="modal-btn" data-dismiss="modal">確定</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  data() {
    return {};
  }
};
</script>
