<template>
  <div
    class="modal fade"
    id="modal-helper-paymethod"
    tabindex="-1"
    role="dialog"
    aria-labelledby="delalert"
  >
    <div class="modal-dialog modal-sm modal-helper">
      <div class="modal-content">
        <div class="modal-title">付款方式</div>
        <div class="helper-content">
          <!-- 付款方式是團主開放給團友的付款方式，可以同時存在，但是團友必須選擇一種。 -->
          任何團友付款都屬匯款,如團友使用悠遊pay或Line
          pay可在團購中說明：匯款後五碼輸入99999
        </div>
        <div class="modal-btn-block">
          <div class="modal-btn" data-dismiss="modal">確定</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  data() {
    return {};
  }
};
</script>
