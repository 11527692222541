<template>
  <div class="content-block">
    <h2 class="lg-title">
      <div class="title-word">
        付款方式
        <div
          class="helper"
          data-toggle="modal"
          data-target="#modal-helper-paymethod"
        ></div>
      </div>
    </h2>
    <div
      class="switch-block f2f active"
      v-for="(paymentMethod, index) in paymentMethods"
      :key="`${paymentMethod.name}-${index}`"
    >
      <template v-if="availablePaymentMethods.includes(paymentMethod.name)">
        <div class="s-title">{{ paymentMethod.description }}</div>
        <div class="switcher-wrap">
          <input
            class="switcher"
            :id="`method0${index + 1}`"
            type="checkbox"
            :data-payment-name="paymentMethod.name"
            :checked="paymentMethod.active"
            @input="switchPaymentMethod"
          />
          <label :for="`method0${index + 1}`" v-if="true"></label>
        </div>
      </template>
    </div>
    <div class="switch-block">
      <div class="s-title">
        服務費
        <div
          class="helper"
          data-toggle="modal"
          data-target="#modal-helper-fee"
        ></div>
      </div>
      <div class="switcher-wrap">
        <input
          class="switcher"
          id="method-fee"
          type="checkbox"
          :checked="needHandlingFee === true"
          @input="updateNeedHandlingFee"
        />
        <label for="method-fee"></label>
      </div>
      <div class="hide-input" :class="{ active: needHandlingFee }">
        <div class="input-wrap">
          <input
            type="number"
            placeholder="請輸入價格"
            :value="handlingFee"
            @input="updateHandlingFee"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import defaultPaymentMethods from '@/utils/payment-methods';
import { mapState, mapMutations, mapActions } from 'vuex';
import { clone } from 'lodash';

export default {
  computed: {
    ...mapState('create-group', {
      activePaymentMethods: state => state.paymentMethods,
      pickupLocations: state => state.pickupLocations,
      needHandlingFee: state => state.needHandlingFee,
      handlingFee: state => state.handlingFee,
      deliveryType: state => state.deliveryType,
      availablePaymentMethods: state => state.availablePaymentMethods,
      defaultInitGroupBuyingSettings: state =>
        state.defaultInitGroupBuyingSettings
    }),
    paymentMethods: {
      get() {
        return defaultPaymentMethods.map(ele => {
          if (this.activePaymentMethods.includes(ele.name)) {
            ele.active = true;
          }

          return ele;
        });
      },
      set(val) {
        let activeMethods = val
          .filter(ele => ele.active === true)
          .map(ele => ele.name);

        this.SET_PAYMENT_METHODS(activeMethods);
      }
    }
  },
  watch: {
    deliveryType: {
      immediate: false,
      handler(newVal, oldVal) {
        this.setAvailablePaymentMethods(newVal);
        this.switchDeliveryType();
      }
    },
    activePaymentMethods(val) {
      // if (val.length === 0) {
      //   let paymentMethods = clone(this.paymentMethods);
      //   if (this.availablePaymentMethods[0]) {
      //     let index = this.paymentMethods.findIndex(
      //       ele => ele.name == this.availablePaymentMethods[0]
      //     );
      //     if (index !== -1) {
      //       paymentMethods[index].active = true;
      //       this.paymentMethods = paymentMethods;
      //     }
      //   }
      // }
    }
  },
  methods: {
    ...mapMutations('create-group', [
      'SET_PAYMENT_METHODS',
      'SET_NEED_HANDLING_FEE',
      'SET_HANDLING_FEE'
    ]),
    ...mapActions('create-group', ['setAvailablePaymentMethods']),
    switchPaymentMethod(e) {
      if (this.availablePaymentMethods.length <= 1) {
        return;
      }
      let active = e.target.checked;
      let paymentName = e.target.dataset.paymentName;
      let findIndex = this.paymentMethods.findIndex(
        el => el.name === paymentName
      );
      let paymentMethods = clone(this.paymentMethods);

      if (findIndex !== -1) {
        paymentMethods[findIndex].active = active === true;
      }

      this.paymentMethods = paymentMethods;
    },
    updateNeedHandlingFee(e) {
      this.SET_NEED_HANDLING_FEE(e.target.checked);
    },
    updateHandlingFee(e) {
      this.SET_HANDLING_FEE(e.target.value);
    },
    switchDeliveryType() {
      this.paymentMethods = clone(this.paymentMethods).map((element, index) => {
        if (!this.availablePaymentMethods.includes(element.name)) {
          element.active = false;
        }
        if (this.defaultInitGroupBuyingSettings?.paymentMethods) {
          let paymentMethodIndex = this.defaultInitGroupBuyingSettings.paymentMethods.findIndex(
            ele => ele.name === element.name
          );
          if (paymentMethodIndex != -1) {
            element.active =
              this.defaultInitGroupBuyingSettings.paymentMethods[
                paymentMethodIndex
              ]?.active === true &&
              this.availablePaymentMethods.includes(
                this.defaultInitGroupBuyingSettings.paymentMethods[
                  paymentMethodIndex
                ]?.name
              );
          }
        }
        return element;
      });
    }
  }
};
</script>
