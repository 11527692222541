<template>
  <div
    class="modal fade"
    id="modal-helper-dataload"
    tabindex="-1"
    role="dialog"
    aria-labelledby="delalert"
  >
    <div class="modal-dialog modal-sm modal-helper">
      <div class="modal-content">
        <div class="modal-title">從本網站帶出商品資料</div>
        <div class="helper-content">選擇後可帶入商品資料，不需輸入資料。</div>
        <div class="modal-btn-block">
          <div class="modal-btn" data-dismiss="modal">確定</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  data() {
    return {};
  }
};
</script>
