import ModalHelperLimitnum from '@/components/Modals/ModalHelperLimitnum';
import ModalHelperDataload from '@/components/Modals/ModalHelperDataload';
import ModalHelperCargo from '@/components/Modals/ModalHelperCargo';
import ModalHelperPaymethod from '@/components/Modals/ModalHelperPaymethod';
import ModalHelperFee from '@/components/Modals/ModalHelperFee';
import ModalHelperShowinfo from '@/components/Modals/ModalHelperShowinfo';

export default {
  ModalHelperLimitnum,
  ModalHelperDataload,
  ModalHelperCargo,
  ModalHelperPaymethod,
  ModalHelperFee,
  ModalHelperShowinfo
};
