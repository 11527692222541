import { uploadImage } from '@/api/upload';

export default {
  data() {
    return {
      imgUrl: null,
      isUploading: false
    };
  },
  methods: {
    previewFiles(e) {
      let files = e.target.files;
      if (files.length === 0) {
        return;
      }
      //一選擇圖片就顯示
      var reader = new FileReader();
      reader.onload = this.imageIsLoaded;
      reader.readAsDataURL(files[0]);

      //上傳之後再顯示
      this.uploadImage(files[0]);
    },
    imageIsLoaded(e) {
      $('.group-img img').attr('src', e.target.result);
      $('.group-img img').fadeIn();
    },
    uploadImage(file) {
      this.isUploading = true;
      uploadImage(file)
        .then(data => {
          this.isUploading = false;
          this.imgUrl = data.url;
          if (this.uploadImageSuccessCb) {
            this.uploadImageSuccessCb(data);
          }
        })
        .catch(err => {
          this.isUploading = false;
        });
    }
  }
};
