<template>
  <div class="content-block">
    <div class="switch-block">
      <div class="s-title">
        是否將開團統計資訊顯示在團友跟團內
        <div
          class="helper"
          data-toggle="modal"
          data-target="#modal-helper-showinfo"
        ></div>
      </div>
      <div class="switcher-wrap">
        <input
          class="switcher"
          id="switcher02"
          type="checkbox"
          :checked="showStatistics === true"
          @input="updateShowStatistics"
        />
        <label for="switcher02"></label>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
export default {
  computed: {
    ...mapState('create-group', {
      showStatistics: state => state.showStatistics
    })
  },
  methods: {
    ...mapMutations('create-group', ['SET_SHOW_STATISTICS']),
    updateShowStatistics(e) {
      this.SET_SHOW_STATISTICS(e.target.checked);
    }
  }
};
</script>
