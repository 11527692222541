<template>
  <div class="main-content">
    <!-- 商品選擇區塊 -->
    <ProductBlock @edit-product="onEditProduct"></ProductBlock>
    <!-- 廠商商品提示 -->
    <VendorHintBlock></VendorHintBlock>
    <!-- 廠商商品說明 -->
    <ContentBlock></ContentBlock>
    <!-- 開團資訊 -->
    <GroupInfoBlock></GroupInfoBlock>
    <!-- 取貨設定區塊 -->
    <ShippingBlock></ShippingBlock>
    <!-- 付款設定區塊 -->
    <PaymentBlock></PaymentBlock>
    <!-- 團主說明 -->
    <RemarkBlock></RemarkBlock>
    <!-- 啟用顯示統計資訊 -->
    <ShowStatisticsBlock v-if="enabledStatistics"></ShowStatisticsBlock>
    <div class="bottom-hint-block">
      建議開團主可以在通訊軟體建立群組，日後分享訊息較便利
    </div>
    <div class="bottom-btn-block nofloat">
      <a
        class="btn-cta"
        href="javascript:void(0)"
        @click.prevent="initiateOrder"
      >
        送出
      </a>
    </div>
    <ModalHelperLimitnum />
    <ModalHelperDataload />
    <ModalHelperCargo />
    <ModalHelperPaymethod />
    <ModalHelperFee />
    <ModalHelperShowinfo />

    <EditProductModal
      :edit-product="editProduct"
      @edit-product-modal-hide="onEndEditProduct"
    />
  </div>
</template>

<script>
import GroupInfoBlock from '@/components/CreateGroup/GroupInfoBlock';
import VendorHintBlock from '@/components/CreateGroup/VendorHintBlock';
import ProductBlock from '@/components/CreateGroup/ProductBlock/index';
import EditProductModal from '@/components/CreateGroup/ProductBlock/EditProductModal';
import ShippingBlock from '@/components/CreateGroup/ShippingBlock';
import PaymentBlock from '@/components/CreateGroup/PaymentBlock';
import RemarkBlock from '@/components/CreateGroup/RemarkBlock';
import ContentBlock from '@/components/CreateGroup/ContentBlock';
import ShowStatisticsBlock from '@/components/CreateGroup/ShowStatisticsBlock';
import HelperModalComponents from '@/views/create-group/utils/helper-modal';
import { mapState, mapActions, mapMutations } from 'vuex';
import { initiateOrder } from '@/api/group-buying';
import { throttle } from 'lodash';

//Line轉換碼
window._lt(
  'send',
  'cv',
  {
    type: 'Conversion'
  },
  ['147c03a1-7872-4efb-9f6c-82f9e33aaed9']
);

const moment = require('moment');

const dafaultEndDate = () => {
  return moment()
    .add(1, 'days')
    .set('hour', '12')
    .set('minute', '00')
    .format('YYYY-MM-DD HH:mm');
};

// @ is an alias to /src

export default {
  components: {
    //main components
    GroupInfoBlock,
    ProductBlock,
    ShippingBlock,
    PaymentBlock,
    RemarkBlock,
    ContentBlock,
    ShowStatisticsBlock,
    VendorHintBlock,
    //sub components
    EditProductModal,
    //helper
    ...HelperModalComponents
  },
  data() {
    return {
      editProduct: null,
      enabledStatistics: process.env.VUE_APP_ENABLED_STATISTICS === 'true'
    };
  },
  mounted() {
    $('.search-filter > input').click(function() {
      $('.del').addClass('active');
    });
    $('.del').click(function() {
      $(this).removeClass('active');
      $('.searching').val('');
    });
    $('.search-list-menu .item').click(function() {
      $(this)
        .addClass('active')
        .siblings()
        .removeClass('active');
    });
  },
  computed: {
    ...mapState('create-group', {
      group: state => state
    }),
    productId() {
      return this.$route.query.product_id;
    },
    groupOrderId() {
      return this.$route.query.group_id;
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(val) {
        this.setInitialOptions({
          productId: this.productId,
          groupId: this.groupOrderId
        });
      }
    }
  },
  methods: {
    ...mapActions('create-group', ['setInitialOptions', 'reset']),
    ...mapMutations('create-group', ['RESET']),
    onEditProduct(product) {
      this.editProduct = product;
    },
    onEndEditProduct() {
      this.editProduct = null;
    },
    initiateOrder: throttle(function() {
      let postData = this.getPostData();
      console.log(postData);
      initiateOrder(postData).then(data => {
        this.RESET();
        this.$router.push({
          name: 'CreatedGroupInfo',
          params: {
            group_id: data.id
          }
        });
      });
    }, 1000),
    getPostData() {
      let endDate = this.group.endDate ? this.group.endDate : dafaultEndDate();

      let postData = {
        products: this.group.products,
        name: this.group.name,
        end_date: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
        end_by_reach_amount: this.group.endByReachAmount,
        reach_amount: this.group.reachAmount,
        delivery_type: this.group.deliveryType,
        payment_methods: this.group.paymentMethods,
        need_handling_fee: this.group.needHandlingFee,
        handling_fee: this.group.handlingFee,
        remark: this.group.remark,
        content: this.group.content,
        show_statistics: this.group.showStatistics,
        from_website: this.group.fromWebsite,
        cover: this.group.cover
      };

      //面交
      if (this.group.deliveryType === 1) {
        postData.pickup_locations = this.group.pickupLocations;
      }

      return postData;
    }
  }
};
</script>

<style lang="scss">
.main-content {
  position: relative;
  &.open {
    height: 90vh;
    overflow: hidden;
  }
}
</style>
