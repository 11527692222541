<template>
  <div
    class="modal fade"
    id="modal-helper-limitNum"
    tabindex="-1"
    role="dialog"
    aria-labelledby="delalert"
  >
    <div class="modal-dialog modal-sm modal-helper">
      <div class="modal-content">
        <div class="modal-title">以數量到達優先結團</div>
        <div class="helper-content">不選擇是以時間結束結團</div>
        <div class="modal-btn-block">
          <div class="modal-btn" data-dismiss="modal">確定</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  data() {
    return {};
  }
};
</script>
