<template>
  <div class="content-block">
    <h2 class="lg-title">
      <div class="title-word">
        領貨方式
        <div
          class="helper"
          data-toggle="modal"
          data-target="#modal-helper-cargo"
        ></div>
      </div>
    </h2>
    <div class="input-tab-block">
      <div
        class="radio-block"
        v-for="(type, index) in deliveryTypes"
        :key="`${type.id}${index}`"
      >
        <input
          :id="type.id"
          type="radio"
          name="delivery"
          :checked="type.value == deliveryType"
          :value="type.value"
          @input="updateDeliveryType"
        />
        <label :for="type.id">{{ type.text }}</label>
      </div>
    </div>
    <div
      class="hidden-block"
      :class="{ active: deliveryType == 1 }"
      id="taketime"
    >
      <div class="new-item-list f2f-list">
        <div
          class="new-item"
          v-for="(item, index) in pickupLocations"
          :key="item.id"
        >
          <div class="item-top">
            <div class="input-wrap" style="position:relative">
              <TimePicker
                :time="item.time_from"
                placeholder="請選擇開始時間"
                :data-index="index"
                data-key="time_from"
                @update-time="updateTime"
              ></TimePicker>
            </div>
            <div class="input-wrap" style="position:relative">
              <TimePicker
                :time="item.time_to"
                placeholder="請選擇結束時間"
                :data-index="index"
                data-key="time_to"
                @update-time="updateTime"
              ></TimePicker>
            </div>
            <div class="item-tool">
              <div
                class="cta del"
                :data-item-index="index"
                @click="removePickupLocation"
              ></div>
            </div>
          </div>
          <div class="item-botm">
            <div class="input-wrap">
              <input
                type="text"
                placeholder="請輸入取貨地址"
                v-model="item.location"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="cta-additem" @click="addPickupLocation">新增取貨時間地址</div>
    </div>
  </div>
</template>

<script>
import deliveryTypes from '@/utils/delivery-types';
import TimePicker from '@/components/TimePicker';
import { mapState, mapMutations } from 'vuex';
export default {
  components: {
    TimePicker
  },
  data() {
    return {
      deliveryTypes: deliveryTypes
    };
  },
  computed: {
    ...mapState('create-group', {
      deliveryType: state => state.deliveryType,
      pickupLocations: state => state.pickupLocations
    })
  },
  methods: {
    ...mapMutations('create-group', [
      'SET_DELIVERY_TYPE',
      'SET_PICKUP_LOCATIONS'
    ]),
    updateTime(time, option) {
      let pickupLocations = this.pickupLocations;
      let index = option.index;
      let key = option.key;

      this.pickupLocations[index][key] = time;
      this.SET_PICKUP_LOCATIONS(pickupLocations);
    },
    addPickupLocation() {
      let newItem = this.getNewPickupLocation();
      this.pickupLocations.push(newItem);
      this.SET_PICKUP_LOCATIONS(this.pickupLocations);
    },
    removePickupLocation(e) {
      let itemIndex = e.target.dataset.itemIndex;
      this.pickupLocations.splice(itemIndex, 1);
      this.SET_PICKUP_LOCATIONS(this.pickupLocations);
    },
    getNewPickupLocation() {
      return {
        time_from: null,
        time_to: null,
        location: null
      };
    },
    updateDeliveryType(e) {
      this.SET_DELIVERY_TYPE(e.target.value);
    }
  }
};
</script>
