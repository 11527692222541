<template>
  <div
    class="modal fade"
    id="modal-helper-cargo"
    tabindex="-1"
    role="dialog"
    aria-labelledby="delalert"
  >
    <div class="modal-dialog modal-sm modal-helper">
      <div class="modal-content">
        <div class="modal-title">領貨方式</div>
        <div class="helper-content">
          面交和郵寄因對象和產品不同，
          <br />
          需分開開團。
        </div>
        <div class="modal-btn-block">
          <div class="modal-btn" data-dismiss="modal">確定</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  data() {
    return {};
  }
};
</script>
