export default {
  data() {
    return {
      loadAll: true //this for disable created loadData
    };
  },
  computed: {
    firstLoad() {
      return this.dataList.length === 0;
    }
  },
  methods: {
    clickLoadData() {
      if (this.firstLoad) {
        this.refreshData();
      }
    }
  }
};
