<template>
  <div class="content-block">
    <h2 class="lg-title">
      <div class="title-word">開團設定</div>
    </h2>
    <div class="upload-pic-block">
      <div class="group-img">
        <img :src="group.cover" alt="" />
        <div class="groupimg">
          <input type="file" accept="image/*" @change="previewFiles" />
        </div>
      </div>
    </div>
    <div class="input-block">
      <div class="input-wrap">
        <input
          type="text"
          placeholder="請輸入開團名稱"
          :value="group.name"
          @input="updateName"
        />
      </div>
      <div class="input-wrap" style="position:relative">
        <DateRangePicker
          :ref="'groupEnd'"
          @updateDate="updateEndDate"
          :value="group.endDate"
          time-title="請輸入結團時間,若不輸入系統將自動帶入隔天中午結團"
        />
      </div>
    </div>
    <div class="switch-block">
      <div class="s-title">
        以數量到達優先結團
        <div
          class="helper"
          data-toggle="modal"
          data-target="#modal-helper-limitNum"
        ></div>
      </div>
      <div class="switcher-wrap">
        <input
          class="switcher"
          id="limitNum"
          type="checkbox"
          :checked="group.endByReachAmount === true"
          @input="updateEndByReachAmt"
        />
        <label for="limitNum"></label>
      </div>
      <div
        class="hide-input"
        block="limitNum"
        :class="{ active: group.endByReachAmount }"
      >
        <div class="input-wrap">
          <input
            type="tel"
            placeholder="請輸入數量"
            :val="group.reachAmount"
            @input="updateReachAmt"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateRangePicker from '@/components/DateRangePicker';
import { mapState, mapMutations } from 'vuex';
import UploadImageMixin from '@/mixins/upload-image';

export default {
  mixins: [UploadImageMixin],
  components: {
    DateRangePicker
  },
  computed: {
    ...mapState('create-group', {
      group: state => state
    })
  },
  watch: {
    imgUrl(newVal, oldVal) {
      this.SET_COVER(newVal);
    }
  },
  methods: {
    ...mapMutations('create-group', [
      'SET_COVER',
      'SET_NAME',
      'SET_END_DATE',
      'SET_END_TIME',
      'SET_END_BY_REACH_AMOUNT',
      'SET_REACH_AMOUNT'
    ]),
    updateName(e) {
      this.SET_NAME(e.target.value);
    },
    updateEndDate(val) {
      this.SET_END_DATE(val.startDate);
    },
    updateEndByReachAmt(e) {
      this.SET_END_BY_REACH_AMOUNT(e.target.checked);
    },
    updateReachAmt(e) {
      this.SET_REACH_AMOUNT(e.target.value);
    }
  }
};
</script>
