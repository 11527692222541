<template>
  <div class="content-block">
    <h2 class="lg-title">
      <div class="title-word">商品資訊</div>
    </h2>
    <div class="switch-block" v-if="!hideWebsiteProducts">
      <div class="s-title">
        是否從本網站帶出商品資料
        <div
          class="helper"
          data-toggle="modal"
          data-target="#modal-helper-dataload"
        ></div>
      </div>
      <div class="switcher-wrap">
        <input
          class="switcher"
          :id="`fromwebsite-switcher01`"
          type="checkbox"
          v-model="fromWebsiteSwitcher"
        />
        <label :for="`fromwebsite-switcher01`"></label>
      </div>
    </div>
    <div class="hidden-block active" id="typeown">
      <div class="new-item-list">
        <div
          class="new-item"
          v-for="(item, index) in products"
          :key="`items-${item.id}`"
        >
          <div class="item-top">
            <template v-if="!fromWebsite">
              <div class="input-wrap">
                <input
                  type="text"
                  placeholder="請輸入品項"
                  :value="item.name"
                  :data-item-index="index"
                  @input="updateProductName"
                />
              </div>
            </template>
            <template v-else>
              <div
                class="input-wrap select-menu-provider"
                @click="showCompanyModal(index)"
              >
                <input
                  type="text"
                  placeholder="請輸入公司"
                  autocomplete="off"
                  readonly="readonly"
                  v-model="companies[index].name"
                />
              </div>
              <div
                class="input-wrap select-menu-item"
                @click="showProductModal(index)"
              >
                <input
                  type="text"
                  placeholder="請輸入品項"
                  autocomplete="off"
                  readonly="readonly"
                  v-model="item.name"
                />
              </div>
              <ModalSelectProvider
                ref="select-provider"
                :index="index"
                @choose-company="chooseCompany"
              />
              <ModalSelectProduct
                ref="select-product"
                :index="index"
                :company="companies[index]"
                @choose-product="chooseProduct"
              />
            </template>
          </div>

          <div class="item-botm">
            <span>每</span>
            <div class="input-wrap">
              <select
                v-if="fromWebsite === true"
                :value="item.unit"
                :data-item-index="index"
                @change="updateProductUnit"
              >
                <option
                  v-for="(unit, index) in item.units"
                  :key="`p-${item.name}-${unit}-${index}`"
                >
                  {{ unit }}
                </option>
              </select>
              <input
                v-else
                type="text"
                placeholder="單位"
                :value="item.unit"
                :data-item-index="index"
                @input="updateProductUnit"
              />
            </div>
            <div class="input-wrap">
              <input
                type="number"
                placeholder="單價"
                :value="item.unit_price"
                :data-item-index="index"
                @input="updateProductUnitPrice"
              />
            </div>
            <div class="item-tool">
              <a
                class="cta edit"
                href="#"
                :data-item-index="index"
                @click.prevent="editProductInfo"
              ></a>
              <div
                class="cta del"
                :data-item-index="index"
                @click="removeItem"
              ></div>
            </div>
          </div>
          <div class="item-botm">
            <template v-if="!fromWebsite">
              <vue-tags-input
                placeholder="輸入樣式：尺寸、顏色、口味"
                v-model="item.spec_name"
                :tags="item.tag_spec_names"
                :data-item-index="index"
                @tags-changed="updateProductSpecs($event, index)"
              />
            </template>
            <template v-else-if="item.available_spec_names.length > 0">
              <vue-tags-input
                placeholder="輸入樣式：尺寸、顏色、口味"
                v-model="item.spec_name"
                :tags="item.tag_spec_names"
                :data-item-index="index"
                :add-only-from-autocomplete="true"
                :autocompleteItems="item.available_spec_names"
                @tags-changed="updateProductSpecs($event, index)"
              />
            </template>
          </div>
          <!-- example -->
          <!-- <div class="item-botm">
            <vue-tags-input
              placeholder="輸入樣式：尺寸、顏色、口味"
              v-model="tag"
              :tags="tags"
              :autocompleteItems="autocompleteItems"
              @tags-changed="newTags => (tags = newTags)"
            />
          </div> -->
        </div>
      </div>
      <div class="cta-additem" @click="addItem">新增品項</div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import ModalSelectProvider from '@/components/Modals/ModalSelectProvider';
import ModalSelectProduct from '@/components/Modals/ModalSelectProduct';
import { getDefaultProduct } from '@/utils/create-group-product';
import { isEmpty, map } from 'lodash';
import VueTagsInput from '@johmun/vue-tags-input';

export default {
  components: {
    ModalSelectProvider,
    ModalSelectProduct,
    VueTagsInput
  },
  data() {
    return {
      companies: [],
      hideWebsiteProducts: process.env.VUE_APP_HIDE_WEBSITE_PRODUCTS === 'true',

      //tags input example value
      tag: 'Also valid8ed',
      tags: [
        {
          text: 'custom class',
          classes: 'custom-class'
        },
        {
          text: 'valid tag'
        },
        {
          text: 'toShort'
        },
        {
          text: '8 is invalid'
        },
        {
          text: 'duplicate'
        },
        {
          text: 'duplicate'
        },
        {
          text: 'Inline styled tag',
          style:
            'color: #56c1da; background-color: transparent; border: 1px solid #56c1da'
        }
      ],
      autocompleteItems: [
        {
          text: 'invalid'
        },
        {
          text: 'Invalid cause of "1"'
        },
        {
          text: 'valid item'
        }
      ]
    };
  },
  computed: {
    ...mapState('create-group', {
      defaultVendor: state => state.defaultVendor,
      fromWebsite: state => state.fromWebsite,
      products: state => state.products,
      websiteProducts: state => state.websiteProducts
    }),
    fromWebsiteSwitcher: {
      get() {
        return this.fromWebsite;
      },
      set(val) {
        this.setFromWebSite(val);
      }
    }
  },
  watch: {
    products: {
      immediate: true,
      handler() {
        if (this.fromWebsite === true) {
          this.setCompaneis();
        }
      }
    }
  },
  methods: {
    ...mapActions('create-group', ['setFromWebSite']),
    ...mapMutations('create-group', ['SET_PRODUCTS']),
    getDefaultProduct(product = {}) {
      return getDefaultProduct(product);
    },
    addItem() {
      let newItem = this.fromWebsite
        ? this.getDefaultProduct({
            vendor: {
              id: this.defaultVendor.id,
              name: this.defaultVendor.name
            }
          })
        : this.getDefaultProduct();

      this.products.push(newItem);
      this.SET_PRODUCTS(this.products);
    },
    removeItem(e) {
      let index = e.target.dataset.itemIndex;
      this.products.splice(index, 1);
      this.SET_PRODUCTS(this.products);
    },
    updateProductName(e) {
      let index = e.target.dataset.itemIndex;
      let value = e.target.value;
      this.products[index].name = value;
      this.SET_PRODUCTS(this.products);
    },
    updateProductUnit(e) {
      let index = e.target.dataset.itemIndex;
      let value = e.target.value;
      this.products[index].unit = value;
      this.SET_PRODUCTS(this.products);
    },
    updateProductUnitPrice(e) {
      let index = e.target.dataset.itemIndex;
      if (index) {
        let value = e.target.value ? parseInt(e.target.value) : 0;
        this.products[index].unit_price = value;
        this.SET_PRODUCTS(this.products);
      }
    },
    editProductInfo(e) {
      let index = e.target.dataset.itemIndex;
      if (index) {
        let product = this.products[index];
        product.index = index;
        this.$emit('edit-product', product);
      }
    },
    setCompaneis() {
      for (let i = 0; i < this.products.length; i++) {
        if (this.companies[i] === undefined || this.companies[i].id === null) {
          this.companies[i] = isEmpty(this.products[i].vendor)
            ? { id: null, name: null }
            : this.products[i].vendor;
        }
      }
    },
    chooseCompany(company) {
      this.$set(this.companies, company.index, {
        id: company.id,
        name: company.name
      });
    },
    chooseProduct(row) {
      let product =
        row.product === null
          ? this.getDefaultProduct()
          : this.getDefaultProduct(row.product);

      this.$set(this.products, row.index, product);
      // this.SET_PRODUCTS(this.products);
    },
    showCompanyModal(index) {
      this.$refs['select-provider'][index].clickLoadData();
      $(`#modal-select-provider${index}`).modal('show');
    },
    showProductModal(index) {
      this.$router.push({
        name: 'SearchHome',
        query: { vendor_id: this.companies[index]?.id }
      });

      //舊模式選單
      // this.$refs['select-product'][index].clickLoadData();
      // $(`#modal-select-product${index}`).modal('show');
    },
    updateProductSpecs(newSpecs, index) {
      this.products[index].tag_spec_names = newSpecs;
      this.products[index].selected_spec_names = map(newSpecs, function(ele) {
        return ele.text;
      });
      this.SET_PRODUCTS(this.products);
    }
  }
};
</script>

<style lang="css">
.item-botm {
  margin-bottom: 10px;
}

/* style the background and the text color of the input ... */
.vue-tags-input {
  background: #f8f8f8;
  width: 100%;
}

.vue-tags-input .ti-new-tag-input {
  background: transparent;
  /* color: #b7c4c9; */
  font-size: 14px;
}

.vue-tags-input .ti-input {
  padding: 4px 10px;
  transition: border-bottom 200ms ease;

  border-radius: 5px;
}

/* we cange the border color if the user focuses the input */
.vue-tags-input.ti-focus .ti-input {
  border: 1px solid #50af5e;
  border-radius: 5px;
}

/* some stylings for the autocomplete layer */
.vue-tags-input .ti-autocomplete {
  /* background: #283944; */
  /* border: 1px solid #8b9396; */
  border-top: none;
}

/* the selected item in the autocomplete layer, should be highlighted */
.vue-tags-input .ti-item.ti-selected-item {
  background: #50af5e;
  /* color: #283944; */
}

/* style the placeholders color across all browser */
.vue-tags-input ::-webkit-input-placeholder {
  color: #a4b1b6;
}

.vue-tags-input ::-moz-placeholder {
  color: #a4b1b6;
}

.vue-tags-input :-ms-input-placeholder {
  color: #a4b1b6;
}

.vue-tags-input :-moz-placeholder {
  color: #a4b1b6;
}

/* default styles for all the tags */
.vue-tags-input .ti-tag {
  position: relative;
  background: #50af5e;
  /* color: #283944; */
}

/* we defined a custom css class in the data model, now we are using it to style the tag */
.vue-tags-input .ti-tag.custom-class {
  background: transparent;
  border: 1px solid #ebde6e;
  color: #ebde6e;
  margin-right: 4px;
  border-radius: 0px;
  font-size: 13px;
}

/* the styles if a tag is invalid */
.vue-tags-input .ti-tag.ti-invalid {
  background-color: #e88a74;
}

/* if the user input is invalid, the input color should be red */
.vue-tags-input .ti-new-tag-input.ti-invalid {
  color: #e88a74;
}

/* if a tag or the user input is a duplicate, it should be crossed out */
.vue-tags-input .ti-duplicate span,
.vue-tags-input .ti-new-tag-input.ti-duplicate {
  text-decoration: line-through;
}

/* if the user presses backspace, the complete tag should be crossed out, to mark it for deletion */
.vue-tags-input .ti-tag:after {
  transition: transform 0.2s;
  position: absolute;
  content: '';
  height: 2px;
  width: 108%;
  left: -4%;
  top: calc(50% - 1px);
  background-color: #000;
  transform: scaleX(0);
}

.vue-tags-input .ti-deletion-mark:after {
  transform: scaleX(1);
}
</style>
