<template>
  <div :class="[{ active: isEditModalOpen }, `fakeModal`]">
    <header>
      <div
        class="prev"
        :style="{ display: 'block' }"
        @click="closeEditModal"
      ></div>
      <h1 class="page-title">{{ pageTitle }}</h1>
    </header>
    <div class="main-content white">
      <div class="content-block">
        <h2 class="lg-title">商品圖</h2>
        <div class="upload-pic-block big">
          <div class="group-img">
            <img :src="productCover" alt="" ref="product-cover" />
            <div class="groupimg">
              <input type="file" accept="image/*" @change="previewFiles" />
            </div>
          </div>
        </div>
      </div>
      <div class="content-block">
        <h2 class="lg-title">商品規格或介紹</h2>
        <div class="input-block">
          <div class="input-wrap">
            <VueEditor
              v-model="productContent"
              :editorOptions="editorSettings"
              :editorToolbar="customToolbar"
              @image-added="handleImageAdded"
              :use-custom-image-handler="true"
            ></VueEditor>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadImageMixin from '@/mixins/upload-image';
import { mapState, mapMutations } from 'vuex';
import { get } from 'lodash';
import { VueEditor } from 'vue2-editor';
import EditorMixin from '@/mixins/vue2-editor';

export default {
  mixins: [UploadImageMixin, EditorMixin],
  components: {
    VueEditor
  },
  props: {
    editProduct: {
      required: false,
      type: Object
    }
  },
  data() {
    return {
      isEditModalOpen: false
    };
  },
  computed: {
    ...mapState('create-group', {
      products: state => state.products
    }),
    productContent: {
      get() {
        if (this.editProduct === null) {
          return;
        }
        // conten 改成 spec_description
        return get(this.products[this.editProduct.index], 'spec_description');
      },
      set(value) {
        this.updateProductContent(value);
      }
    },
    productCover() {
      if (this.editProduct === null) {
        return;
      }
      return get(this.products[this.editProduct.index], 'cover');
    },
    pageTitle() {
      return this.$route.meta.pageTitle;
    }
  },
  watch: {
    editProduct(newVal, oldVal) {
      if (newVal !== null) {
        this.showEditModal();
      }
    },
    imgUrl(newVal, oldVal) {
      if (this.editProduct === null) {
        return;
      }
      this.products[this.editProduct.index].cover = newVal;
      this.SET_PRODUCTS(this.products);
    }
  },
  methods: {
    ...mapMutations('create-group', ['SET_PRODUCTS']),
    showEditModal() {
      window.scrollTo(0, 0);
      this.isEditModalOpen = true;
      this.$emit('edit-product-modal-show', true);
    },
    closeEditModal() {
      this.$emit('edit-product-modal-hide', this.product);
      this.isEditModalOpen = false;
      this.imgUrl = null;
    },
    updateProductContent(value) {
      if (this.editProduct === null) {
        return;
      }

      this.products[this.editProduct.index].spec_description = value;
      this.SET_PRODUCTS(this.products);
    },
    imageIsLoaded(e) {
      $(this.$refs['product-cover']).attr('src', e.target.result);
      $(this.$refs['product-cover']).fadeIn();
    }
  }
};
</script>

<style lang="scss" scoped>
.main-content {
  position: relative;
  &.open {
    height: 90vh;
    overflow: hidden;
  }
}
// [class^='fakeModal-']
[class^='fakeModal'] {
  z-index: 500;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  bottom: 0;
  background-color: #fff;
  transition: 0.4s;
  overflow: hidden;
  header {
    display: none;
  }
}
[class^='active'] {
  z-index: 500;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  right: 0;
  bottom: 0;
  background-color: #fff;
  transition: 0.4s;
  overflow: hidden;
  header {
    display: block;
  }
}
</style>
