<template>
  <div
    class="modal fade"
    :id="`modal-select-product${index}`"
    tabindex="-1"
    role="dialog"
    aria-labelledby="delalert"
  >
    <div class="modal-dialog modal-sm modal-select">
      <div class="modal-close" data-dismiss="modal"></div>
      <div class="modal-content">
        <div class="modal-title">請選擇品項</div>
        <SearchInput
          placeholder="請輸入品項名稱"
          class="search-filter"
          @on-search="updateSearch"
        />
        <div class="search-list-menu">
          <div
            class="item"
            v-for="(product, index) in dataList"
            :key="index"
            :data-product-index="index"
            @click="chooseProduct"
          >
            {{ product.name }}
          </div>
          <!-- //TODO: 載入 -->
          <span v-if="!loadAll" @click="getData" class="text-center">
            載入更多
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getProducts } from '@/api/products';
import PaginateMixin from '@/mixins/paginate';
import PaginateExtendMixin from '@/mixins/paginate-extend-ref';
import SearchInput from '@/components/SearchInput';

export default {
  mixins: [PaginateMixin, PaginateExtendMixin],
  components: {
    SearchInput
  },
  props: {
    company: {
      required: true,
      type: Object
    },
    index: {
      required: true,
      type: Number
    }
  },
  data() {
    return {
      api: getProducts
    };
  },
  computed: {
    companyId() {
      return this.company.id;
    },
    query() {
      return {
        vendor: this.companyId,
        page: this.page,
        per_page: this.per_page,
        search: this.search,
        ...this.sort
      };
    }
  },
  watch: {
    companyId: {
      immediate: true,
      handler(val, old) {
        if (old !== undefined && val !== old) {
          this.reset();
          this.reSelectCompany();
        }
      }
    }
  },
  methods: {
    chooseProduct(e) {
      let modelID = `#modal-select-product${this.index}`;
      let productIndex = e.target.dataset.productIndex;
      let product = this.dataList[productIndex];

      $(modelID).modal('hide');

      this.$router.push({
        name: 'ProductPage',
        params: {
          product_id: product.id
        },
        query: {
          group: 1
        }
      });

      // this.$emit('choose-product', {
      //   index: this.index,
      //   product: this.dataList[productIndex]
      // });
      // $(modelID).modal('hide');
    },
    reSelectCompany() {
      this.$emit('choose-product', {
        index: this.index,
        product: null
      });
    },
    updateSearch(search) {
      this.search = search;
    }
  }
};
</script>
