<template>
  <div
    class="modal fade"
    id="modal-helper-showinfo"
    tabindex="-1"
    role="dialog"
    aria-labelledby="delalert"
  >
    <div class="modal-dialog modal-sm modal-helper">
      <div class="modal-content">
        <div class="modal-title">開團資訊顯示</div>
        <div class="helper-content">
          如果開啟，該次開團會顯示總購買量和人數，建議不要開啟，否則團友會要求每次都要開啟。
        </div>
        <div class="modal-btn-block">
          <div class="modal-btn" data-dismiss="modal">確定</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  data() {
    return {};
  }
};
</script>
